<template>
  <div class="content-wrap">
    <Header></Header>
    <div class="content-box">
      <ul class="breadcrumb">
        <li>
          <router-link to="/">首页</router-link>
          <span class="iconfont iconiconfontyoujiantou-copy"></span>
        </li>
        <li>
          <router-link :to="'/list/'+keywords">{{keywords}}</router-link>
          <span class="iconfont iconiconfontyoujiantou-copy"></span>
        </li>
      </ul>
      <div class="search-cmp-num">
        为您找到
        <span style="color:#FA4040;margin:0 5px">{{totalCount}}</span>家符合条件的企业
      </div>
      <ul class="cmp-list">
        <li @click="intoDetail(item.id)" v-for="item in companyList" :key="item.id">
          <CmpItem :info="item"></CmpItem>
        </li>
      </ul>
      <div class="clearfix pagination-box">
          <el-pagination style="margin:20px 0;float:right" :page-size.sync="pageSize" :current-page.sync="pageNum" background layout="prev, pager, next" :total="totalCount"></el-pagination>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Header from "@/components/header";
import CmpItem from "@/components/cmpItem";
import Footer from "@/components/footer";
export default {
  props: ["keywords"],
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      totalCount:0,
      companyList:[],   //企业列表
    };
  },
  methods: {
    ...mapActions(["getQueryCompany"]),
    intoDetail(id){
        console.log(id)
        this.$router.push(`/detail/${id}`)
    }
  },
  async mounted() {
    window.scrollTo(0,0);
    let res = await this.getQueryCompany({
      keyword: this.keywords,
    });
    this.totalCount = res.data.length
    this.companyList = res.data;
  },
  components: {
    Header,
    Footer,
    CmpItem
  },
  watch:{
    async $route(newVal){
      console.log('route')
      console.log(newVal)
      let res = await this.getQueryCompany({
        keyword: newVal.params.keywords,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      });
      this.totalCount = res.data.length
      this.companyList = res.data;
    }
  }
};
</script>
<style scoped lang="less">
a {
  color: #666;
  &.active {
    color: #000;
  }
}
.content-wrap {
  padding-top: 86px;
  .content-box {
    width: 1200px;
    margin: 0 auto;
  }
  .breadcrumb {
    display: flex;
    font-size: 18px;
    color: #666;
    margin-top: 40px;
    margin-bottom: 20px;
    & > li {
      display: flex;
      align-items: center;
      .iconiconfontyoujiantou-copy {
        font-size: 12px;
        margin: 0 6px;
      }
      &:last-child {
        .iconiconfontyoujiantou-copy {
          display: none;
        }
      }
    }
  }
  .search-cmp-num {
    width: 990px;
    height: 60px;
    border: 1px solid #e4e4e4;
    border-radius: 10px 10px 0 0;
    background-color: #fff;
    padding: 0 20px;
    line-height: 58px;
    color: #333333;
    font-size: 24px;
    margin-bottom: 10px;
  }
  .cmp-list {
      width:990px;
    & > li {
      margin-bottom: 20px;
      cursor: pointer;
    }
  }
  .pagination-box{
      width:990px;
  }
}
</style>