<template>
  <div class="search-box-wrap">
    <div class="intro-box d_flex_c_c">
      <img src="@/assets/images/logo2.png" class="logo2" />
      <p>一键查询</p>
    </div>
    <div class="search-box d_flex_c_c">
      <div class="input-box-wrap">
        <div class="input-box-inner">
          <span class="iconfont iconsousuo1"></span>
          <input type="text" v-model="cmpName" placeholder="请输入企业名称/代码号/标签" class="input-box" @keyup.enter="search"/>
        </div>
        <div @click="search" class="search-btn d_flex_c_c" >搜索</div>

        <!-- 搜索检索 -->
        <ul v-if="companyList.length>0" class="search-result">
          <li @click="toDetail(item.id)" v-for="item in companyList" :key="item.id">
            <img :src="Setting.assetsUrl+item.logoimage" class="c_logo">
            <div v-html="item.name"></div>
          </li>
        </ul>
      </div>
      <div class="tips">
        <div class="hot">热搜：制造业、批发和零售、信息传输</div>
        <div class="country-sys" @click="toOtherWeb">建档查询：国家企业信用信息公示系统</div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions} from 'vuex'
import Setting from '../setting';
export default {
  data() {
    return {
      cmpName:'',
      companyList:[],
      Setting,
    };
  },
  methods:{
    ...mapActions(['getQueryCompany']),
    search(){
      this.$emit('search',this.cmpName)
    },
    toDetail(id){
      console.log(id)
      this.$router.push(`/detail/${id}`)
    },
    toOtherWeb(){
      window.open('http://www.gsxt.gov.cn/index.html')
    }
  },
  watch:{
    async cmpName(newVal){
      if(newVal){
        let res = await this.getQueryCompany({
          keyword:newVal,
        })
        this.companyList = res.data;
      }else{
        this.companyList = []
      }
    }
  }
};
</script>
<style scoped lang="less">
.search-box-wrap {
  width: 1200px;
  height: 240px;
  border-radius: 15px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  & > .intro-box {
    width: 300px;
    background-color: #ff7c25;
    color: #fff;
    font-size: 24px;
    flex-direction: column;
    border-radius: 15px 0 0 15px;
    & > .logo2 {
      width: 186px;
      height: 45px;
      margin-bottom: 24px;
    }
  }
  & > .search-box {
    flex: 1;
    flex-direction: column;
    & > .input-box-wrap {
      width: 800px;
      height: 76px;
      position: relative;
      border: 1px solid #ccc;
      background-color: #eee;
      display: flex;
      justify-content: space-between;
      &>.search-result{
        position: absolute;
        width:100%;
        left: 0;
        top: 75px;
        border: 1px solid #999;
        color: #333;
        font-size: 18px;
        z-index: 4;
        &>li{
          height: 55px;
          display: flex;
          align-items: center;
          padding: 0 20px;
          cursor: pointer;
          border-bottom: 1px solid #eee;
          background-color: #fff;
          &:last-child{
            border-bottom: 0;
          }
          &:hover{
            background-color: #eee;
          }
          .c_logo{
            width: 35px;
            height: 35px;
            margin-right: 10px;
          }
        }
      }
      & > .search-btn {
        height: 100%;
        width: 110px;
        background-color: #ff7c25;
        color: #fff;
        font-size: 22px;
        cursor: pointer;
      }
      & > .input-box-inner {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 20px;
        & > .input-box {
          margin-left: 15px;
          flex: 1;
          font-size: 16px;
          color: #333;
          &::placeholder {
            color: #666;
          }
        }
      }
      .iconsousuo1 {
        font-size: 32px;
        color: #808080;
      }
    }
    & > .tips {
      width: 800px;
      margin-top: 25px;
      display: flex;
      justify-content: space-between;
      & > .hot {
        font-size: 18px;
        color: #808080;
      }
      & > .country-sys {
        font-size: 16px;
        color: #5684fc;
        cursor: pointer;
      }
    }
  }
}
</style>