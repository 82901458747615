<template>
    <div class="content-wrap">
        <Header></Header>
        <div class="content-box clearfix">
            <ul class="list">
                <li @click="changeIndex(index)" v-for="(item,index) in list" :key="index">{{item.title}}</li>
            </ul>
            <div class="info">
                <div class="title" v-html="list[index].title"></div>
                <div class="content" v-html="list[index].content"></div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import Header from '@/components/header'
import Footer from '@/components/footer'
export default {
    props:['id'],
    data(){
        return{
            list:[
                {
                    title:'关于我们',
                    content:``
                },
                {
                    title:'协议声明',
                    content:``
                }
            ],
            index:0
        }
    },
    mounted(){
        if(this.id){
            this.index = this.id
        }
        if(this.$route.params.id){
            this.index = this.$route.params.id;
        }
        this.getAppConfig('pc_about_us').then(res=>{
            this.list[0].content = res.data;
        });
        this.getAppConfig('pc_agreement').then(res=>{
            this.list[1].content = res.data;
        });
        window.scrollTo(0,0);
    },
    methods:{
        ...mapActions(["getAppConfig"]),
        changeIndex(i){
            this.index = i
        }
    },
    components:{
        Header,Footer
    }
}
</script>
<style scoped lang="less">
.content-wrap{
    padding-top: 86px;
    .content-box{
        width: 1200px;
        margin: 25px auto;
        color: #333333;
        font-size: 24px;
        .list{
            list-style: none !important;
            float: left;
            width: 225px;
            background-color: #fff;
            border: 1px solid #E4E4E4;
            border-radius: 10px 0 0 0;
            padding: 10px 0 10px 30px;
            margin-right: 16px;
            &>li{
                margin: 30px 0;
                line-height: 1;
                cursor: pointer;
            }
        }
        .info{
            float: left;
            width: 840px;
            border-radius: 0 10px 0 0;
            background-color: #fff;
            min-height: 350px;
            padding: 30px;
            font-size: 18px;
            .title{
                margin-bottom: 25px;
                font-size: 24px;
            }
            .content{
                line-height: 1.4;
                text-indent: 36px;
            }
        }
    }
}
</style>