
const IS_PROD = ['production', 'prod'].includes(process.env.NODE_ENV); // 判断是否为生产环境
const servertype = '0'; // 0 测试服
const serverHttpobj = {
    '0': { // 测试服
        'httphost': 'https://www.qyoucheng.com',
    },
};
export const host = serverHttpobj[servertype].httphost;
export const prefix= IS_PROD?`${host}`:'/allapi';
export const getCompanyList = `/index/company/search`; // 获取企业列表
export const getQueryCompany = `/index/company/search`; // 模糊搜索企业列表
export const getCompanyListByScore = ()=> `/api/company/company/index?page=1&size=100`; // 获取企业分类列表
export const getCompanyDetail = `/index/company/detail/`; // 获取企业详情

