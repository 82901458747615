<template>
  <div class="footer-wrap">
    <div class="about-us-wrap">
      <div class="left-part">
        <div class="part1">
          <p>
            <span class="h1">联系我们</span>
            <span class="h2">/solition</span>
          </p>
          <ul class="part-list">
            <li>工作时间: {{ config.worktime }}</li>
            <li>公司邮箱：<a :href="'mailto:'+config.mail">{{ config.mail }}</a></li>
          </ul>
        </div>
        <div class="part1">
          <p>
            <span class="h1">关于我们</span>
            <span class="h2">/about us</span>
          </p>
          <ul class="part-list intro">
            <li @click="toSelf(0)">公司介绍</li>
            <li @click="toSelf(1)">协议声明</li>
            <!-- <li>免责声明</li>
            <li>加入我们</li> -->
          </ul>
        </div>
      </div>
      <ul class="right-part">
        <li>
          <img :src="config.wxfw" alt="微信服务号" class="info-img" />
          <p>微信服务号</p>
        </li>
        <li>
          <img :src="config.android" alt="安卓下载" class="info-img" draggable="false"/>
          <p>安卓下载</p>
        </li>
        <li>
          <img :src="config.ios" alt="IOS下载" class="info-img" draggable='false'/>
          <p>IOS下载</p>
        </li>
      </ul>
    </div>
    <div class="copyright-wrap">
      <a class="copy" href="https://beian.miit.gov.cn/" target="_blank">{{ config.copyright }} </a>
    </div>
    <div class="intro-wrap" v-if="true">
      <!-- <div class="app">
        <img :src="config.android" class="app-img" alt="诚信app" />
        <p>扫码下载诚信APP</p>
      </div> -->
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(['config'])
  },
  methods:{
    toSelf(id){
      this.$router.push({path:`/self/${id}`})
    }
  }
};
</script>
<style scoped lang="less">
.footer-wrap {
  width: 100%;
  min-width: 1200px;
  background-color: #2b343d;
  & > .about-us-wrap {
    width: 1200px;
    margin: 0 auto;
    border-bottom: 1px solid #e5e5e5;
    padding: 40px 0;
    display: flex;
    & > .left-part {
      display: flex;
      border-right: 1px solid #f2f6f9;
      & > .part1 {
        color: #999999;
        font-size: 16px;
        margin-right: 65px;
        .h1 {
          font-size: 22px;
          color: #fff;
          vertical-align: top;
          display: inline-block;
          margin-right: 5px;
        }
        .h2 {
          font-size: 16px;
          color: #fff;
          vertical-align: bottom;
          display: inline-block;
        }
        .part-list {
          & > li {
            margin-top: 30px;
          }
          a{
            border: none;
            color: #999999;
          }
        }
        .intro{
          & > li {
            cursor: pointer;
          }
        }
      }
    }
    .right-part {
      flex: 1;
      display: flex;
      padding: 20px 0 0 90px;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      & > li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .info-img {
          width: 130px;
          height: 130px;
          margin-bottom: 25px;
        }
      }
    }
  }
  & > .copyright-wrap {
    text-align: center;
    padding: 45px 0;
    position: relative;
    z-index: 1;
    .copy{
      color: #999999;
      font-size: 12px;
    }
  }
  & > .intro-wrap {
    width: 100%;
    height: 200px;
    margin-top: -75px;
    background: url("~@/assets/images/dibuxuanfu.png") no-repeat center
      center/auto 100%;
    position: relative;
    .app {
      position: absolute;
      top: 10px;
      width: 130px;
      left: 50%;
      margin-left: 415px;
      color: #fff;
      font-size: 16px;
    }
    .app-img {
      width: 124px;
      height: 124px;
      display: block;
      margin: 0 auto 10px;
    }
  }
}
</style>