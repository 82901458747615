import Vue from 'vue';
import Router from 'vue-router';
import Index from '@/views/index';
import List from '@/views/list';
import Detail from '@/views/detail';
import Self from '@/views/self';

Vue.use(Router);
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
const router =  new Router({
  routes: [
    {
      path: '/',
      name: 'index',
      component: Index,
    },
    {
        path: '/list/:keywords',
        props:true,
        name: 'list',
        component: List,
    },
    {
        path: '/self',
        name: 'self',
        component: Self,
    },
    {
        path: '/self/:id',
        props:true,
        name: 'self',
        component: Self,
    },
    {
        path: '/detail/:id',
        props:true,
        name: 'detail',
        component: Detail,
    }
  ],
});

export default router;
